@import 'libs/assets/theme/mixins.scss';

.field {
  width: 100%;
}

.endAdornment {
  margin-left: 0.25rem;
}

.input {
  @include ellipsis;
  height: 1.25rem;
}

.inputRoot {
  @include ellipsis;
  padding-left: 0.75rem;
}

.searchIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: #929292;
}

.startAdornment {
  margin-right: 0.25rem;
}
